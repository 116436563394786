import {html, LitElement} from "lit";
import {customElement, property} from "lit/decorators.js";

@customElement('my-counter')
export class MyCounter extends LitElement {
    @property()
    public count :number = 0;

    override render() {
        return html`
            <div>
                <div>The button has been clicked ${this.count} times</div>
                <button @click="${this.increment}">Click me!</button>
            </div>
        `;
    }

    private increment() {
        this.count++;
        this.requestUpdate();
        console.log("increment has been clicked " + this.count + " times");
    }
}